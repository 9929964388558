.betSlipDesktop {
  margin: 10px 10px 15px;
  position: relative;

  &__topSection {
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
    margin-bottom: 12px;
    border-bottom: 1px solid #99a0a2;

    &-title {
      font-family: 'Lato';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 1;
      color: #142326;
    }
    &-editStakes {
      width: 80px;
      height: 22px;
      background: #6a9772;
      box-shadow: inset -2px -2px 0px #54785b;
      border-radius: 3px;

      font-family: 'Lato';
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 20px;
      text-align: center;
      color: #ffffff;

      &:active {
        background: rgb(105, 140, 111);
        box-shadow: inset 2px 2px 0px #54785b;
      }
    }
  }
  //&__separator {
  //  width: 100%;
  //  border: 1px solid #99a0a2;
  //  margin: 11px 0;
  //}
  &__betTop {
    display: flex;
    justify-content: space-between;

    font-family: 'Lato';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #142326;

    &-slip {
      // display: flex;
      // justify-content: center;
      // align-items: center;
      padding: 8px 20px;
      cursor: pointer;
      width: 78px;
      height: 33px;
      background: #dedbd7;

      &.active {
        background: white;
      }
    }

    &-openBets {
      // display: flex;
      // justify-content: center;
      // align-items: center;
      padding: 8px 20px;
      cursor: pointer;
      // width: 78px;
      width: 96px;
      height: 33px;
      background: #dedbd7;

      &.active {
        background: white;
      }
    }

    &-editStakesBtn {
      width: 80px;
      height: 28px;
      // background-color: #6a9772;
      background-color: var(--cta-primary-darken);
      font-family: 'Lato';
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 20px;
      text-align: center;
      color: var(--bet-form-stake-buttons-color);
      box-shadow: inset -2px -2px 0px var(--cta-primary-darken);
      border-radius: 3px;
      border-bottom: 1px solid var(--cta-primary-darken);
      border-right: 1px solid var(--cta-primary-darken);
      &:hover {
        background-color: var(cta-primary-darken);
        box-shadow: 1.5px 1.5px 0px 0px var(--cta-primary-darken);
      }
      &:active{
        background-color: var(--primary);
        color: var(--bet-form-stake-buttons-color);
        border-top: 1px solid var(--cta-primary-darken);
        border-left: 1px solid var(--cta-primary-darken);
        box-shadow: none;
        border-bottom: none;
        border-right: none;
      }
    }
  }

    &__topFilters {
      display: flex;
      justify-content: start;
      padding: 10px 6px 20px 6px;
      color: black;
      background-color: white;

      &-item {
        display: flex;
        margin-right: 6px;
        align-items: center;
        cursor: pointer;
        &-title{
          font-size: 12px;
        }
      }
    }

    &__content {
      overflow-x: hidden;
      max-height: 250px;
      &-competitionTitle {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 14px;

        color: #000000;
      }
    }
    .betSlipDesktop__topFilters-item-check {
      width: 12px;
      height: 12px;
      background: rgba(255, 255, 255, 0.01);
      border: 1px solid #dddddd;
      box-sizing: border-box;
      box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.25);
      border-radius: 3px;
      margin-right: 6px;
      &.checked {
        background: #3982f7;
        &::after {
          content: url('../../assets/images/checkMark.svg');
          width: 10px;
          height: 10px;
          position: relative;
          bottom: 6px;
          right: 1px;
          color: white;
        }
      }
    }
  }

.betSlipDesktop__betMain-shadow{
  background-color: rgba(0, 0, 0, 0.7);
  width: 100%;
  position: absolute;
  z-index: 3;
  height: 100%;
}
