.OpenBets {
    margin-bottom: 16px;
    padding: 0 0px;
    margin-top: 10px;

    &__btn {
        margin: 0;
        width: 100%;
        border-radius: 0;
        --border-radius: 0;
    }

    &__tv-icon {
        width: 12px;
        height: 10px;
        margin-left: 7px;
        font-family: 'apollo';

        &:before {
            content: "";
        }
    }

    &__content {
        background: #000;
        display: flex;
        justify-content: center;

        &__video {
            width: 550px;
            height: 309px;
        }
    }
}


@media screen and (min-width: 1024px) {
    .OpenBets{
        overflow-x: hidden;
        max-height: 50vh;
        
    } 
    .OpenBets::-webkit-scrollbar {
        width: 1em;
      }
    .OpenBets::-webkit-scrollbar-thumb {
        background-color: darkgrey;
        // outline: 1px solid slategrey;
      }
    .OpenBets::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      }
}